.ui.primary.button, .ui.primary.buttons .button {
    background-color: #000000;
}
.ui.primary.button:hover, .ui.primary.buttons .button:hover {
    background-color: #3a3a3a;
}
.ui.primary.button:active, .ui.primary.buttons .button:active {
    background-color: #545454;
}
.ui.primary.button:focus, .ui.primary.buttons .button:focus {
    background-color: #3a3a3a;
}