.fullscreen-bg {
position: relative;
float:left;
overflow: hidden;
z-index: -100;

background-image: url("/images/kace-rodriguez-75513-unsplash.jpg");
background-size:cover;
height:100vh;
width:75%
}

.fullscreen-bg__video {
position: absolute;
top: 50%;
left: 50%;
min-width: 100%;
min-height: 100%;
width: auto;
height: auto;
z-index: -100;
-webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
}
  